import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Hero from "./Hero";
import Projects from "./Projects";
import About from "./About";

const Homepage = () => {
  return (
    <>
      <Header />
      <main className="container m-auto">
<Hero/>
<Projects/>
<About/>


      </main>
      <Footer />
    </>
  );
};

export default Homepage;

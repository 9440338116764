import person from "../../assests/img/person-nobg.png";
import background from "../../assests/img/location-bg.png";

const About = () => {
  return (
    <div className="About-section relative">
      <div className="w-full my-10">
        <img
          className="about-section__background absolute -z-10 w-full border-gray-800 h-full"
          src={background}
          alt="location bg"
        />
        <div className="about-section__about flex justify-between">
          <div className="about-section__about__text font-semibold p-4 rounded-tl-[32px] rounded-br-[32px] border-2 h-40 lg:h-full m-auto bg-purple-400 opacity-90">
            <h1 className="about-section__about_text__heading">
              I'm Rohtash Talan
            </h1>
            <div>
              <p>I was born in India</p>
              <p>I was born in India</p>
              <p>I was born in India</p>
            </div>
          </div>
          <div className="about-section__about__pic">
            <img
              className="about-section__about__pic__src"
              src={person}
              alt="Rohtash Talan pic"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

import whatsapp from "../assests/img/social-icons/whatsapp.png";
import facebook from "../assests/img/social-icons/facebook.png";
import twitter from "../assests/img/social-icons/twitter.png";
import instagram from "../assests/img/social-icons/instagram.png";
import linkedin from "../assests/img/social-icons/linkedin.png";
import github from "../assests/img/social-icons/github.png";
import wave from "../assests/img/wave.png";

const Footer = () => {
  return (
    <>
      <footer className="w-full">
        <div className="contact mx-auto bg-gray-400 my-4 py-8">
          <div className="bg-transparent	">
          <div className="contact-text text-center font-bold text-2xl w-32 mx-auto bg-transparent">
            Contact Us
          </div>
          <div className="bg-gradient-to-b from-indigo-300 to-blue-700 p-3 w-2 m-auto rounded-full"></div>
          </div>

          <div className="flex flex-wrap flex-row-reverse justify-between w-full m-auto p-10">
            <div className="contact-form w-full lg:w-2/5 mx-auto shadow-md rounded-lg py-4 my-2 shadow-blue-700 bg-gray-100">
              <form className="border-none p-2 rounded w-4/5 mx-auto">
                <h2 className="font-bold text-xl">Get in touch</h2>
                <div className="w-full m-auto">
                  <div className="w-full flex justify-between my-2">
                    <input
                      className="shadow bg-gray-200 px-2 py-1 mr-1 rounded w-1/2"
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Email.."
                    />
                    <input
                      className="shadow bg-gray-200 px-2 py-1 ml-1 rounded w-1/2"
                      type="phone"
                      id="phone"
                      name="Phone"
                      placeholder="Phone"
                    />
                  </div>
                  <textarea
                    className="shadow bg-gray-200 px-2 py-1 my-2 rounded w-full"
                    rows="5"
                    placeholder="Message..."
                  ></textarea>

                  <button className="bg-blue-700 my-4 px-8 py-2 text-center text-white font-semibold rounded">
                    Send
                  </button>
                </div>
              </form>
            </div>
            <div className="contact-info w-full lg:w-2/5 mx-auto shadow-lg rounded-lg py-4 my-2 shadow-gray-100 bg-gray-700 text-white">
              <div className="w-4/5 lg:w-3/5 m-auto">
              <div className="flex content-evenly m-auto my-6">
                <span class="material-icons-outlined text-6xl text-blue-500 mr-8">
                  location_on
                </span>
                <p>
                  <h1 className="text-3xl font-semibold">Location</h1>
                  <span>Delhi/India</span>
                </p>
              </div>
              <div className="flex content-evenly m-auto my-6">
                <span class="material-icons-outlined text-6xl text-blue-500 mr-8">
                  phone
                </span>
                <p>
                  <h1 className="text-3xl font-semibold">Phone</h1>
                  <span>
                    <a href="tel:+917011725647">+91 7011 72 5647</a>
                  </span>
                </p>
              </div>
              <div className="flex content-evenly m-auto my-6">
                <span class="material-icons-outlined text-6xl text-blue-500 mr-8">
                  email
                </span>
                <p>
                  <h1 className="text-3xl font-semibold">email</h1>
                  <span>
                    <a href="mailto:rohtashtalancompany@gmail.com">
                      rohtashtalancompany@gmail.com
                    </a>
                  </span>
                </p>
              </div>
              </div>
            </div>
          </div>
        </div>

        <div id="social-links w-80 m-auto my-20">
          <div className="flex justify-around w-80 m-auto my-20">
            <a
              href="https://www.github.com/rohtashtalan"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-12" src={github} alt="github" />
            </a>
            <a
              href="https://wa.me/917011725647"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-12" src={whatsapp} alt="whatsapp" />
            </a>
            <a
              href="https://www.fb.com/RohtashTalan16"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-12" src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.twitter.com/talanrohtash"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-12" src={twitter} alt="twitter" />
            </a>
            <a
              href="https://www.instagram.com/rohtashtalan"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-12" src={instagram} alt="instagram" />
            </a>
            <a
              href="https://www.linkedin.com/in/rohtashtalan"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-12" src={linkedin} alt="linkedin" />
            </a>
          </div>
        </div>

        <div id="copyright-section">
          <div className="flex flex-col pt-6 text-xl font-semibold text-white">
            <img
              className="w-100 h-48 -mb-14"
              height="200px"
              src={wave}
              alt="github"
            />
            <div className="w-100 m-auto">Coded by Rohtash Talan</div>
            <div className="w-100 m-auto text-sm mt-1">
              All Rights Reserved 2022
            </div>
          </div>

          {/* <div>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
        </div><div>Icons made by <a href="https://www.flaticon.com/authors/riajulislam" title="riajulislam">riajulislam</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;

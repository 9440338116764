import Homepage from "./pages/homepage";
import './output.css'

function App() {
  return (
  <Homepage/>
  );
}

export default App;

const Header = () => {
  return(<>
  
  <header className="bg-white flex justify-between p-2 py-3 shadow-xl">
    <h1 className="w-2/5 text-xl font-bold">Rohtash Talan
        {/* <img alt="Rohtash Talan"/> */}
    </h1>
    <nav className="w-3/5">
        <div className="flex justify-around">
            <a href='/'>Home</a>
            <a href='#projects'>Projects</a>
            <a href='#about'>About</a>
            <a href='#services'>Services</a>
            <a href='#contact'>Contact</a>
        </div>
    </nav>
  </header>
  
  </>)
};

export default Header;

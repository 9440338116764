import { useState } from "react";
import person from "../../assests/img/person.jpg";
import project from "../../assests/json/projects.json";

const Projects = () => {
  const [projectCat, setProjectCat] = useState(false);
  const [selectedCat, setSelectedCat] = useState(false);

  if (!projectCat) {
    let setArray = [];
    const categoriesProject = project.map((item) => item.Categories);
    categoriesProject.map((item) => setArray.push(item));
    let NewArray = setArray.filter((c, index) => {
      return setArray.indexOf(c) === index;
    });
    setProjectCat(NewArray);
    setSelectedCat(NewArray[0]);
  }

  return (
    <div className="Projects-section" id="projects">
      <div className="my-10 mb-20 w-5/6 mx-auto">
        <div className="project--heading flex flex-col justify-center items-center">
          <h2 className="text-2xl font-bold">Projects</h2>
          <span className="p-3 rounded-full h-4 bg-gradient-to-b from-indigo-500 to-blue-500 my-2 mb-5"></span>
        </div>
        <div className="project--categories w-full mx-auto">
          <div className="flex justify-center flex-wrap w-full text-center text-xl">
            {projectCat &&
              projectCat.map((item) =>
                selectedCat === item ? (
                <div className="border-2 rounded-lg border-blue-600 px-6 py-1 font-semibold m-1 bg-purple-800 text-white capitalize">
                    {item}
                  </div>
                ) : (
                  <div onClick={()=>(setSelectedCat(item))} className="border-2 rounded-lg border-blue-600 px-6 py-1 font-semibold m-1 capitalize cursor-pointer">
                    {item}
                  </div>
                )
              )}
          </div>
        </div>
        <div className="project__lists my-10">
          <div className="cards grid items-center lg:grid-cols-4 md:grid-cols-2">
            {project &&
              project.map((item) =>
                item.Categories === selectedCat ? (
                  <div className="card w-72 md:w-72 lg:w-60 md:m-4 shadow-lg shadow-gray-700  rounded-[16px] m-2 mx-auto h-auto">
                    <img
                      className="h-42 w-full rounded-t-[16px]"
                      alt={item.title}
                      src={item.Screenshot}
                    />
                    <div className="card-body p-2">
                      <div className="card-title text-blue-600 font-bold text-md px-2">
                        {item.title}
                      </div>
                      <div className="card-btns flex justify-between p-2">
                        <a
                          href={item.Github}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button className="bg-blue-500 px-2 p-1 rounded-lg text-white font-medium">
                            Github
                          </button>
                        </a>
                        <a
                          href={item.liveLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button className="bg-gray-200 px-2 p-1 rounded-lg  font-medium">
                            Live link
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;

import person from "../../assests/img/person.jpg";
import vscode from "../../assests/img/programming-language/vscode.svg";
import wordpress from "../../assests/img/programming-language/wordpress.svg";
import appwrite from "../../assests/img/programming-language/appwrite.svg";
import css3 from "../../assests/img/programming-language/css3.svg";
import express from "../../assests/img/programming-language/express.svg";
import firebase from "../../assests/img/programming-language/firebase.svg";
import html5 from "../../assests/img/programming-language/html5.svg";
import javascript from "../../assests/img/programming-language/javascript.svg";
import mongodb from "../../assests/img/programming-language/mongodb.svg";
import mysql from "../../assests/img/programming-language/mysql.svg";
import nodejs from "../../assests/img/programming-language/nodejs.svg";
import npm from "../../assests/img/programming-language/npm.svg";
import reactjs from "../../assests/img/programming-language/react.svg";
import tailwindcss from "../../assests/img/programming-language/tailwindcss.svg";

const Hero = () => {
  return (
    <div className="hero-section realtive w-full lg:mb-10">
      <div className="flex flex-col items-center lg:my-16">
        <div className="hero-about w-4/5 mx-auto lg:w-4/6 my-10 lg:my-0 bg-indigo-100 opacity-80 flex flex-col justify-center lg:justify-around items-center lg:flex-row border-2 rounded-tl-[60px] rounded-br-[60px] p-8">
          <img
            className="w-32 rounded-full mx-auto bg-blue-500 opacity-100 p-1 lg:w-56 lg:mx-0"
            src={person}
            alt="Rohtash-pic"
          />
          <div className="hero-details mx-auto opacity-100 lg:w-96 lg:mx-0">
            <h1 className="font-bold pt-6">
              I'm <span className="text-blue-900 text-3xl">Rohtash Talan</span>
            </h1>
            <p className="py-4">
              Passionate FullStack Javascript Web Developer, Build web
              applications using the MERN stack (MongoDB, Express, React, and
              Node.js)
            </p>
            <div className="flex items-center justify-between">
              <a href="#projects" className="bg-blue-700 rounded-lg px-4 py-2 text-white text-md text-center flex items-center justify-center ">
                See Projects {"  "}
                <span className="material-icons-outlined text-sm ml-1">
                  arrow_forward
                </span>
              </a>
              <button className="text-sm font-semibold text-blue-700 text-center flex items-center justify-center">
                <a href="https://www.canva.com/design/DAFXEiQYmAc/i23wsif1vq24kLACUrpA6A/view" target="_blank" rel="noreffer">
                  Download Resume
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap items-center lg:ml-48 px-16 w-full mx-auto lg:px-24 items-center mt-10">
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={html5} alt="html5-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              Html5
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={css3} alt="css-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              css3
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={tailwindcss} alt="tailwindcss-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              tailwindCSS
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={javascript} alt="javascript-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              Javascript
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={reactjs} alt="reactjs-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              ReactJs
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={nodejs} alt="nodejs-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              NodeJs
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={express} alt="express-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              ExpressJs
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={mongodb} alt="mongodb-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              MongoDB
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={appwrite} alt="appwrite-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              appWrite
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={mysql} alt="mysql-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              MySql
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={vscode} alt="vscode-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              vscode
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={wordpress} alt="wordpress-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              Wordpress
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={firebase} alt="firebase-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
              Firebase
            </div>
          </div>
          <div className="w-16 mr-1 mt-1 rounded-full bg-gray-100 p-px relative">
            <img className="m-2 z-0" src={npm} alt="npm-pic" />
            <div class="opacity-0 hover:opacity-90 hover:cursor-pointer absolute inset-0 z-10 flex justify-center items-center font-bold bg-gray-300 rounded-full">
            npm
            </div>
          </div>
        </div>
      </div> 

      <div className="bg-section absolute -z-10 left-0 top-12 w-full flex pb-10 h-[600px]">
        <div className="left-bg w-2/6 bg-[#A6BCFA]"></div>
        <div className="right-bg py-20 relative">
          <span class="material-icons-outlined  text-[#A6BCFA] font-bold text-7xl  absolute top-8">
            close
          </span>
          <span class="material-icons-outlined text-[#A6BCFA] font-bold text-8xl absolute bottom-8 right-8">
            circle
          </span>
        </div> 
      </div>
    </div>
  );
};

export default Hero;
